import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { css } from '@emotion/react';

export default function Icon({ typeEle, styleEle, classEle, styleOthers, id }) {
  const phoneCss = css`
    &.gatsby-image-wrapper img {
      ${styleEle};
      width: calc(100vw * (calc(14 / var(--width_base))));
      height: calc(100vw * (calc(21 / var(--width_base))));
    }
  `;

  const phone2Css = css`
    &.gatsby-image-wrapper img {
      ${styleEle};
      @media (max-width: 767px) {
        width: calc(100vw * (calc(29 / var(--width_base))));
        height: calc(100vw * (calc(29 / var(--width_base))));
      }
    }
  `;

  const whatsappCss = css`
    ${styleOthers};
    &.gatsby-image-wrapper img {
      ${styleEle};
      width: calc(100vw * (calc(112 / var(--width_base))));
      height: calc(100vw * (calc(112 / var(--width_base))));
      @media (max-width: 767px) {
        width: calc(100vw * (calc(84 / var(--width_base))));
        height: calc(100vw * (calc(84 / var(--width_base))));
      }
    }
  `;

  const salasWhatsappCss = css`
    ${styleOthers};
    &.gatsby-image-wrapper img {
      ${styleEle};
      width: calc(100vw * (calc(39 / var(--width_base))));
      height: calc(100vw * (calc(39 / var(--width_base))));
      @media (max-width: 767px) {
        width: calc(100vw * (calc(30 / var(--width_base))));
        height: calc(100vw * (calc(30 / var(--width_base))));
      }
    }
  `;
  const menuCss = css`
    ${styleEle};
    width: calc(100vw * (calc(33 / var(--width_base))));
    height: calc(100vw * (calc(25 / var(--width_base))));
  `;

  const redCss = css`
    ${styleEle};
    height: calc(100vw * (calc(26 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(20 / var(--width_base))));
    }

    & path {
      @media (max-width: 767px) {
        fill: #7fbc03;
      }
    }
  `;

  const iconMap = css`
    ${styleEle};
    width: calc(100vw * (calc(59 / var(--width_base))));
    height: calc(100vw * (calc(59 / var(--width_base))));
  `;

  const iconEstado = css`
    ${styleEle};
    width: calc(100vw * (calc(96 / var(--width_base))));
    height: calc(100vw * (calc(52 / var(--width_base))));
  `;

  const iconArea = css`
    ${styleEle};
    width: calc(100vw * (calc(59 / var(--width_base))));
    height: calc(100vw * (calc(59 / var(--width_base))));
  `;

  const iconVentas = css`
    ${styleEle};
    height: calc(100vw * (calc(31 / var(--width_base))));
    width: calc(100vw * (calc(31 / var(--width_base))));

    @media (max-width: 767px) {
      height: calc(100vw * (calc(25 / var(--width_base))));
      width: calc(100vw * (calc(25 / var(--width_base))));
    }

    // & path {
    //   @media (max-width: 767px) {
    //     fill: #999;
    //   }
    // }
  `;

  const iconVentasContacto = css`
    ${styleEle};
    height: calc(100vw * (calc(39 / var(--width_base))));
    width: calc(100vw * (calc(39 / var(--width_base))));
    @media (max-width: 767px) {
      height: calc(100vw * (calc(30 / var(--width_base))));
      width: calc(100vw * (calc(30 / var(--width_base))));
    }
  `;

  const iconMapa = css`
    height: calc(100vw * (calc(61 / var(--width_base))));
    width: calc(100vw * (calc(61 / var(--width_base))));
    transform: translate(-50%, -100%);
  `;

  const iconClose = css`
    width: calc(100vw * (calc(10 / var(--width_base))));
    height: calc(100vw * (calc(10 / var(--width_base))));
    pointer-events: none;
    @media (max-width: 767px) {
      width: calc(100vw * (calc(17 / var(--width_base))));
      height: calc(100vw * (calc(17 / var(--width_base))));
    }
  `;

  const close = css`
    width: calc(100vw * (calc(33 / var(--width_base))));
    height: calc(100vw * (calc(33 / var(--width_base))));
    pointer-events: none;
    @media (max-width: 767px) {
      width: calc(100vw * (calc(25 / var(--width_base))));
      height: calc(100vw * (calc(25 / var(--width_base))));
    }

    & polygon {
      @media (max-width: 767px) {
        fill: #7fbc03 !important;
      }
    }
  `;

  const iconShare = css`
    fill: #fff;
    width: calc(100vw * (calc(15 / var(--width_base))));
    height: calc(100vw * (calc(18 / var(--width_base))));
    margin-left: calc(100vw * (calc(5 / var(--width_base))));
    pointer-events: none;
    @media (max-width: 767px) {
      width: calc(100vw * (calc(17 / var(--width_base))));
      height: calc(100vw * (calc(17 / var(--width_base))));
    }
  `;

  return (
    <>
      {typeEle === 'phone' && (
        <StaticImage
          css={phoneCss}
          src="../../../images/icono-phone.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono phone"
        />
      )}
      {typeEle === 'phone2' && (
        <StaticImage
          css={phone2Css}
          src="../../../images/icono-phone2.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono phone"
          objectFit="contain"
        />
      )}
      {typeEle === 'whatsapp' && (
        <StaticImage
          css={whatsappCss}
          src="../../../images/icono-wsp.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono whatsapp"
          objectFit="contain"
        />
      )}
      {typeEle === 'salas-whatsapp' && (
        <StaticImage
          css={salasWhatsappCss}
          src="../../../images/icono-wsp.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono whatsapp"
          objectFit="contain"
          id={id}
        />
      )}
      {typeEle === 'whatsapp2' && (
        <StaticImage
          css={whatsappCss}
          src="../../../images/icono-wsp2.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono whatsapp"
          objectFit="contain"
        />
      )}
      {typeEle === 'estado' && (
        <StaticImage
          css={iconEstado}
          src="../../../images/proyectos-construidos.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono Estado"
          objectFit="contain"
        />
      )}
      {typeEle === 'mapa' && (
        <StaticImage
          css={iconMap}
          src="../../../images/jardin-central.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono Map"
          objectFit="contain"
        />
      )}
      {typeEle === 'area' && (
        <StaticImage
          css={iconArea}
          src="../../../images/distribucion-inteligente.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'menu' && (
        <svg
          viewBox="0 0 32.02 24.23"
          css={menuCss}
          className="hamb-menu icon-menu"
        >
          <line
            className="cls-1 ham-1"
            fill="none"
            stroke="#93ba1f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.26px"
            x1="2.13"
            y1="2.13"
            x2="29.89"
            y2="2.13"
          />
          <line
            className="cls-1 ham-2"
            fill="none"
            stroke="#93ba1f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.26px"
            x1="2.13"
            y1="12.11"
            x2="29.89"
            y2="12.11"
          />
          <line
            className="cls-1 ham-3"
            fill="none"
            stroke="#93ba1f"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="4.26px"
            x1="2.13"
            y1="22.1"
            x2="29.89"
            y2="22.1"
          />
        </svg>
      )}
      {typeEle === 'facebook' && (
        <svg viewBox="0 0 12.68 24.35" css={redCss}>
          <path
            fill="#fff"
            d="M3.75,24.35c0-1.16,0-2.33,0-3.49,0-2.31,0-4.61,0-6.92,0-.67,0-.67-.68-.67-.85,0-1.7,0-2.55,0-.39,0-.5-.14-.49-.51,0-1.12,0-2.24,0-3.35,0-.37.09-.52.49-.51.87,0,1.75,0,2.62,0,.6,0,.6,0,.62-.59,0-1.17,0-2.33.06-3.5a5,5,0,0,1,5-4.8,22.62,22.62,0,0,1,3.49.1c.35,0,.45.16.45.5,0,1,0,2,0,3,0,.36-.15.45-.48.45-.61,0-1.21,0-1.82,0a3.62,3.62,0,0,0-.87.08A1.46,1.46,0,0,0,8.28,5.42a14.11,14.11,0,0,0-.07,2.25c0,.38-.17.86.08,1.13s.78.1,1.18.11c1,0,2,0,3,0-.19,1.4-.37,2.72-.53,4,0,.31-.23.31-.46.31H9.12c-1,0-.91-.12-.91.88,0,3.13,0,6.26,0,9.4,0,.26,0,.53,0,.8Z"
          />
        </svg>
      )}
      {typeEle === 'instagram' && (
        <svg viewBox="0 0 25.72 25.95" css={redCss}>
          <path
            fill="#fff"
            d="M12.74,19.67a6.62,6.62,0,0,1-6.61-6.6,6.84,6.84,0,0,1,6.81-6.83,6.94,6.94,0,0,1,6.65,6.7A6.7,6.7,0,0,1,12.74,19.67Z"
          />
          <path
            fill="#fff"
            d="M25.67,5.47c-.13-3.25-2-5.19-5.14-5.32C15.43-.06,10.31,0,5.21.14,2.05.26.12,2.14.06,5.41c-.09,5-.06,10,0,15,0,3.47,1.93,5.38,5.3,5.48,5,.15,10,.12,15,0,3.39-.08,5.28-2,5.38-5.42.07-2.49,0-5,0-7.48S25.77,8,25.67,5.47ZM13,21.72a8.83,8.83,0,0,1-8.8-8.57A8.61,8.61,0,0,1,12.7,4.41a8.42,8.42,0,0,1,8.7,8.48A8.64,8.64,0,0,1,13,21.72ZM21,5.64A1.82,1.82,0,1,1,22.8,3.82,1.82,1.82,0,0,1,21,5.64Z"
          />
        </svg>
      )}
      {typeEle === 'youtube' && (
        <svg viewBox="0 0 36.08 25.74" css={redCss}>
          <path
            fill="#fff"
            d="M18.05,25.65H15c-3.67,0-7.83.51-11.36-.67C0,23.74,0,19.67,0,16.37c0-3.49.13-7,.15-10.47,0-4,2.91-5.76,6.57-5.83,7.56-.14,15.13,0,22.69,0,3.14,0,6.67,1.51,6.67,6.47s0,11.57-.55,14.88-5,4.3-7.91,4.29Zm-4.71-6.17c4.22-2.22,7.77-3.88,11.06-7L13.34,5.91Z"
          />
        </svg>
      )}
      {typeEle === 'linkedin' && (
        <svg viewBox="0 0 26.31 26.31" css={redCss}>
          {/* <path fill="#fff" d="M26.31,13.16V22.7a3.57,3.57,0,0,1-3.47,3.61H3.58A3.55,3.55,0,0,1,.12,23.65a3.89,3.89,0,0,1-.11-.9q0-9.6,0-19.19A3.55,3.55,0,0,1,2.85.08,3.91,3.91,0,0,1,3.61,0H22.7a3.61,3.61,0,0,1,3.5,2.66,4,4,0,0,1,.11,1Zm-2,0V3.49a1.55,1.55,0,0,0-.33-.9A1.57,1.57,0,0,0,22.7,2H3.55A1.5,1.5,0,0,0,2,3.56q0,9.6,0,19.2a1.37,1.37,0,0,0,.05.4,1.5,1.5,0,0,0,1.5,1.13H22.84a1.53,1.53,0,0,0,1.45-1.58Z"/>
          <path fill="#fff" d="M14.17,22.26V16.58A5.64,5.64,0,0,1,14.35,15a1.68,1.68,0,0,1,1.2-1.25,3.27,3.27,0,0,1,1-.09,1.51,1.51,0,0,1,1.47,1.16,4.64,4.64,0,0,1,.16,1.39c0,2,0,3.95,0,5.93v.12h4.05V16.75a20.59,20.59,0,0,0-.08-2.22,7.58,7.58,0,0,0-.38-1.81,3.55,3.55,0,0,0-2.53-2.39,6.33,6.33,0,0,0-2.45-.16,3.61,3.61,0,0,0-1.52.51,3.05,3.05,0,0,0-1,1.13l-.07.09,0,0V10.13h-4V22.26Zm-6.08,0V10.13h-4V22.26Zm-2-18.21a2,2,0,0,0,0,4.05,2,2,0,1,0,0-4Z"/>
          <path fill="#fff" d="M14.17,22.26h-4V10.13h4v1.75l0,0,.07-.09a3.05,3.05,0,0,1,1-1.13,3.61,3.61,0,0,1,1.52-.51,6.33,6.33,0,0,1,2.45.16,3.55,3.55,0,0,1,2.53,2.39,7.58,7.58,0,0,1,.38,1.81,20.59,20.59,0,0,1,.08,2.22v5.51H18.21v-.12c0-2,0-3.95,0-5.93a4.64,4.64,0,0,0-.16-1.39,1.51,1.51,0,0,0-1.47-1.16,3.27,3.27,0,0,0-1,.09A1.68,1.68,0,0,0,14.35,15a5.64,5.64,0,0,0-.18,1.58v5.68Z"/>
          <path fill="#fff" d="M8.09,22.26h-4V10.13h4Z"/>
          <path fill="#fff" d="M6.07,4.05a2,2,0,1,1-2,2A2,2,0,0,1,6.07,4.05Z"/> */}
          <path
            fill="#fff"
            d="M 24.421428,0 H 1.8727008 C 0.83948661,0 0,0.85122759 0,1.8961829 V 24.403817 C 0,25.448772 0.83948661,26.3 1.8727008,26.3 H 24.421428 C 25.454642,26.3 26.3,25.448772 26.3,24.403817 V 1.8961829 C 26.3,0.85122759 25.454642,0 24.421428,0 Z M 7.9487054,22.542856 H 4.0506696 V 9.9916517 H 7.9545759 V 22.542856 Z M 5.9996875,8.2774554 c -1.2504241,0 -2.2601562,-1.0156027 -2.2601562,-2.2601564 0,-1.2445535 1.0097321,-2.2601562 2.2601562,-2.2601562 1.2445535,0 2.2601561,1.0156027 2.2601561,2.2601562 0,1.2504242 -1.0097321,2.2601564 -2.2601561,2.2601564 z M 22.560469,22.542856 H 18.662432 V 16.4375 c 0,-1.455893 -0.02935,-3.328594 -2.025334,-3.328594 -2.031205,0 -2.342344,1.585045 -2.342344,3.222923 v 6.211027 H 10.396719 V 9.9916517 h 3.739531 v 1.7141963 h 0.05283 c 0.522477,-0.98625 1.796384,-2.0253346 3.692567,-2.0253346 3.945,0 4.678817,2.6006476 4.678817,5.9820756 z"
          />
        </svg>
      )}
      {typeEle === 'svg-mail' && (
        <svg viewBox="0 0 39.49 39.49" css={iconVentasContacto}>
          <circle fill="#eaeaea" cx="19.34" cy="18.85" r="17.05" />
          <path
            fill="#514f51"
            d="M19.79,39.49a19.75,19.75,0,1,1,19.7-19.83A19.74,19.74,0,0,1,19.79,39.49Zm-.12-27.65c-2.47,0-4.94,0-7.4,0-1.76,0-2.48.63-2.5,2.3q-.07,5.59,0,11.18c0,1.61.73,2.3,2.33,2.31q7.49,0,15,0c1.6,0,2.32-.69,2.34-2.3,0-3.73,0-7.45,0-11.18,0-1.67-.75-2.3-2.5-2.31C24.49,11.83,22.08,11.84,19.67,11.84Z"
          />
          <path
            fill="#514f51"
            d="M27.36,13.92a2,2,0,0,1-1,2.65c-1.95,1.09-3.84,2.31-5.71,3.53a1.55,1.55,0,0,1-2,0c-1.9-1.28-3.89-2.43-5.83-3.64-1.19-.74-1.18-.75-1-2.59,2.14,1.34,4.25,2.56,6.26,3.94a2.26,2.26,0,0,0,3,0C23.06,16.49,25.18,15.27,27.36,13.92Z"
          />
        </svg>
      )}
      {typeEle === 'svg-phone' && (
        <svg viewBox="0 0 39.49 39.49" css={iconVentasContacto}>
          <circle fill="#eaeaea" cx="19.74" cy="19.74" r="17.05" />
          <path
            fill="#93ba1f"
            d="M39.49,19.81A19.75,19.75,0,1,1,19.76,0,19.76,19.76,0,0,1,39.49,19.81ZM13.33,10.86c-1,.22-2.72-.72-2.63,1.63A16.75,16.75,0,0,0,24.58,28.36c4.46.74,4,.24,3.88-4a.93.93,0,0,0-.69-1,30.1,30.1,0,0,0-4.2-.59c-.94,0-1.37,1-2,1.58-.47.42-.79.78-1.52.33a15.34,15.34,0,0,1-5.29-5.27c-.32-.53-.52-1,.08-1.42,1.86-1.23,1.87-2.84,1.39-4.84C15.71,10.9,15.89,10.86,13.33,10.86Z"
          />
        </svg>
      )}
      {typeEle === 'svg-calendario' && (
        <svg viewBox="0 0 61 67.75" css={iconVentas}>
          <path
            fill="#fff"
            d="M30.51,67.75c-7.68,0-15.35,0-23,0C2.35,67.73,0,65.43,0,60.28q0-23,0-46C0,8.76,1.89,6.69,7.43,6.52,10,6.44,9.17,4.71,9.1,3.58,9,1.2,9.38.13,12.22.15s3.37,1,3,3.3c-.44,2.56.63,3.12,3.08,3.07,8.33-.16,16.67-.12,25,0,1.92,0,2.64-.4,2.57-2.46C45.72.26,46.9-.49,50.75.26c1.72.34,1.13,1.61,1,2.44-.32,2.71.2,4,3.46,3.9S60.9,9.35,61,13.1c.08,4.83,0,9.66,0,14.49Q61,43.79,61,60c0,5.52-2.25,7.75-7.74,7.76Zm-.41-6.19c7.58,0,15.16-.06,22.74,0,1.76,0,2.25-.51,2.24-2.24q-.11-17.62,0-35.26c0-1.62-.26-2.33-2.14-2.32q-22.47.12-44.92,0c-1.63,0-2.1.49-2.09,2.09,0,11.84.07,23.69,0,35.54,0,1.81.63,2.2,2.3,2.18C15.51,61.51,22.8,61.56,30.1,61.56Z"
          />
        </svg>
      )}
      {typeEle === 'svg-horario' && (
        <svg viewBox="0 0 61.11 61.07" css={iconVentas}>
          <path
            fill="#fff"
            d="M17.14,2.85A35.07,35.07,0,0,1,37.09.5C46.54,3.36,54,8.67,58.32,17.81a30.22,30.22,0,0,1-5.55,33.48A30.46,30.46,0,0,1,19.43,59,31,31,0,0,1,0,31.09a30.33,30.33,0,0,1,6.9-20.22A27.29,27.29,0,0,1,17.14,2.85ZM30.55,6a24.45,24.45,0,1,0,.06,48.9A24.67,24.67,0,0,0,55,30.45,24.64,24.64,0,0,0,30.55,6Z"
            transform="translate(0)"
          />
          <path
            fill="#fff"
            d="M27.46,24.44c0-2.55.08-5.11,0-7.65-.08-2.08,1.35-1.58,2.49-1.66s2.2,0,2.15,1.71c-.1,4,.09,7.94-.09,11.9a3.59,3.59,0,0,0,2.21,3.75c3.39,1.86,6.64,4,10,5.85,1.53.84,1.42,1.61.6,2.85s-1.19,2.21-3,1.07c-4.19-2.73-8.5-5.29-12.82-7.8a2.83,2.83,0,0,1-1.62-2.93C27.54,29.17,27.46,26.8,27.46,24.44Z"
            transform="translate(0)"
          />
        </svg>
      )}
      {typeEle === 'svg-mapa' && (
        <svg viewBox="0 0 42.55 60.59" css={iconVentas}>
          <path
            fill="#fff"
            d="M21.39,0C35.63.07,45.51,13.24,41.75,27.26c-2.48,9.21-7.79,17-13.18,24.64A69.6,69.6,0,0,1,22.29,60c-.75.78-1.19.91-1.95,0-8.21-9.87-16-20-19.49-32.65C-3.07,13.19,7-.07,21.39,0Zm7.49,21.22a7.61,7.61,0,1,0-15.21.19,7.58,7.58,0,0,0,7.68,7.5A7.73,7.73,0,0,0,28.88,21.22Z"
          />
        </svg>
      )}
      {typeEle === 'marker' && (
        <StaticImage
          css={iconMapa}
          src="../../../images/pinMarker.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'marker-logo' && (
        <StaticImage
          css={iconMapa}
          src="../../../images/pinMarkerLogo.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'svg-close' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 378.303 378.303"
          css={iconClose}
        >
          <polygon
            fill="white"
            points="378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018
	28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 "
          />
        </svg>
      )}
      {typeEle === 'close' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 378.303 378.303"
          css={close}
          className="icon-close"
        >
          <polygon
            fill="#4A4A49"
            points="378.303,28.285 350.018,0 189.151,160.867 28.285,0 0,28.285 160.867,189.151 0,350.018
	28.285,378.302 189.151,217.436 350.018,378.302 378.303,350.018 217.436,189.151 "
          />
        </svg>
      )}
      {typeEle === 'svg-share' && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 15.7 17.26"
          css={iconShare}
        >
          <g id="Capa_2" data-name="Capa 2">
            <g id="Layer_3" data-name="Layer 3">
              <g id="z7lzWg">
                <path d="M10,5,8.18,6.22c-.65.43-1.29.87-1.94,1.29-.1.07-.13.12-.09.24a2.82,2.82,0,0,1,0,1.77.15.15,0,0,0,.07.21L10,12.24l0,0,.2-.23a3,3,0,0,1,3.2-.91,3,3,0,0,1,2.22,2.51,3.14,3.14,0,1,1-6.21.86,2.72,2.72,0,0,1,0-.57.21.21,0,0,0-.12-.23l-4-2.64a.18.18,0,0,0-.25,0,3,3,0,0,1-3.18.47A3,3,0,0,1,0,9,3.13,3.13,0,0,1,5.11,6.2a.18.18,0,0,0,.26,0l4-2.64a.24.24,0,0,0,.12-.24,3.11,3.11,0,1,1,.71,1.8Z" />
              </g>
            </g>
          </g>
        </svg>
      )}

      {typeEle === 'edificio' && (
        <StaticImage
          css={styleEle}
          src="../../../images/icon-edificio.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'jardin' && (
        <StaticImage
          css={styleEle}
          src="../../../images/icon-jardin.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'dorm' && (
        <StaticImage
          css={styleEle}
          src="../../../images/dormitorios.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
      {typeEle === 'price' && (
        <StaticImage
          css={styleEle}
          src="../../../images/dinero.png"
          quality={100}
          formats={['auto', 'webp', 'avif']}
          alt="Icono area"
          objectFit="contain"
        />
      )}
    </>
  );
}

Icon.defaultProps = {
  typeEle: '',
  styleEle: {},
  classEle: '',
};
